<template>
  <b-table
    :busy="false"
    ref="refSoalListTable"
    class="position-relative"
    :items="props.data"
    responsive
    :fields="columns"
    primary-key="id"
    show-empty
    striped
    hover
    empty-text="Tidak ada data ditemukan"
  >
    <template #head(pertanyaan)="data">
      <div>
        <vue-mathjax :safe="false" :formula="data.label"></vue-mathjax>
      </div>
    </template>

    <template #head()="data">
      <div class="tw-flex tw-justify-center">
        <vue-mathjax :safe="false" :formula="data.label"></vue-mathjax>
      </div>
    </template>

    <template #cell(pertanyaan)="data">
      <vue-mathjax :safe="false" :formula="data.value"></vue-mathjax>
    </template>

    <template #cell()="data">
      <div class="tw-flex tw-justify-center">
        <b-form-radio
          :disabled="props.disabled"
          v-model="data.item.selected"
          :value="data.field.label"
          style="margin-top:0"
        />
      </div>
    </template>
  </b-table>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api'
import { VueMathjax } from "vue-mathjax";
import {
  BTable,
  BFormRadio
} from "bootstrap-vue";

export default defineComponent({
    components: {
        BTable,
        BFormRadio,
        VueMathjax,
    },
    props: {
        data: {
            required: true,
            default: [],
            type: Array
        },
        disabled: {
            default: false,
            type: Boolean
        }
    },
    setup(props) {
       const columns = computed(() => {
        const col = [
            {
                key: 'pertanyaan', label: "PERTANYAAN", thStyle: {fontWeight: 600, fontSize: "14px" }
            },
        ]
        if(!!props.data.length) {
            props.data[0].jawaban.forEach(item => {
                col.push({
                    key: item, label: item, thStyle: {fontWeight: 600, fontSize: "14px" }
                })
            })
        }


      return col
    })

        return {
            columns,
            props
        }
    },
})
</script>
